import React from 'react';
import Container from "react-bootstrap/Container";
import { Roadmap1 } from "../../assets";

function Roadmap() {
    return (
        <>
            <section className="main-4">
                <Container>
                    <h3 className="main2-a">Roadmap to Collect</h3>
                    <p className="main3-b">
                    Our easy-to-follow instructions assist you in owning your exclusive NFTs in no time. 

                    </p>

                    <img src={Roadmap1} className="roadmap-img" alt="" />
                </Container>
            </section>
        </>
    )
}

export default Roadmap