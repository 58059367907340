import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Contactus() {
    return (
        <>
            <section className='contactus'>
                <Container>
                    <h3 className="main2-a">Collection</h3>
                    <form>
                        <Row>
                            <Col md={6}>
                                <input type="text" name='firstname' className='input-class' placeholder='First Name' required />
                            </Col>
                            <Col md={6}>
                                <input type="text" name='lastname' className='input-class' placeholder='Last Name' required />
                            </Col>
                            <Col md={6}>
                                <input type="email" name='email' className='input-class' placeholder='Your Email' required />
                            </Col>
                            <Col md={6}>
                                <input type="text" name='phone' className='input-class' placeholder='Your Phone' required />
                            </Col>
                            <Col md={12}>
                                <input type="text" name='nft#' className='input-class' placeholder='NFT# (Optional)' />
                            </Col>
                            <Col md={12}>
                                <textarea placeholder='Your Message' name='message' rows={8} className='text-class'></textarea>
                            </Col>
                            <Col md={12}>
                                <input type='submit' name='submit' className='submit-btn' value='Submit' />
                            </Col>
                        </Row>
                    </form>
                </Container>
            </section>
        </>
    )
}

export default Contactus