import React from 'react';
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { Obbles1, Obbles2, Obbles3, Obbles4, Obbles5, Obbles6, Obbles7, Obbles8, Obbles9, Obbles10, Obbles11, Obbles12, Obbles13, Obbles14, Obbles15, Obbles16, Obbles17, Obbles18, Obbles19, Obbles20,Obbles21,Obbles22,Obbles23,Obbles24,Obbles25 } from "../../assets";


function Collection() {
    return (
        <>
            <div className="main-collection">
                <div className="collection-gradient">
                    <h3 className="main2-a">Collection</h3>
                    <p className="main3-b">
                        Explore The Obbles' magnificent collections, where each NFT figure is a masterpiece created in bronze, gold, or silver. These unique items are intended to attract collectors with detailed decorations and concealed Easter eggs that reveal particular NFTs from our collection. Customize your collectibles using puzzle pieces to give a personal touch to your favorite statues. Discover the ideal combination of creativity and innovation in The Obbles' collections.

                    </p>
                </div>
            </div>
            <section className="main-3 collection">
                <Container>
                    {/* <h3 className="collection-a">The Fluffies NFT Collection</h3>
                    <div className="nft-div">
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie1} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie2} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie3} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie4} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie5} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie6} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie7} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie8} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie9} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie10} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie11} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie12} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie13} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie14} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie15} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie16} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie17} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie18} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie19} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie20} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie21} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie22} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie23} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie24} className="imgbox-img" alt="" />
                            </Link>

                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/fluffy-0" target="_blank">
                                <img src={Fluffie25} className="imgbox-img" alt="" />
                            </Link>

                        </div>


                    </div> */}

                    <h3 className="collection-a">The Obble Universe</h3>
                    <div className="nft-div">
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles1} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles2} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles3} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles4} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles5} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles6} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles7} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles8} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles9} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles10} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles11} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles12} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles13} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles14} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles15} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles16} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles17} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles18} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles19} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles20} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles21} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles22} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles23} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles24} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="https://opensea.io/collection/obble-1" target="_blank">
                                <img src={Obbles25} className="imgbox-img" alt="" />
                            </Link>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default Collection