import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FooterLogo } from '../../assets';

function Footer() {
    return (
        <>
            <footer>
                <Container>
                    <Row>
                        <Col md={3}>
                            <Link to="/"><img src={FooterLogo} className='footer-logo' alt='' /></Link>
                            <p className="footer-a">Discover Obbles, the destination of unique NFT collectibles combined with a greater cause. Explore our wide collections and join us in making a positive influence.</p>
                        </Col>
                        <Col md={3}>
                            <h3 className="footer-b">Quick Links</h3>
                            <div className="marketplace-div">
                                <ul className='footer-ul'>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="./Aboutme">About Me</Link></li>
                                    <li><Link to="./Collection">Collection</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3}>
                            <h3 className="footer-b">More Links</h3>
                            <ul className='footer-ul'>
                                <li><Link to="./Roadmap">Roadmap</Link></li>
                                <li><Link to="./Contactus">Contact Us</Link></li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h3 className="footer-b">Social</h3>
                            <ul className='footer-ul'>
                                <li><Link to="#">Facebook</Link></li>
                                <li><Link to="#">Instagram</Link></li>
                                <li><Link to="#">Twitter</Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <Container className='footer-container'>
                    <Row>
                        <Col md={6}>
                            <p className="copyright-text">Copyright © 2024 . All reights reserved</p>
                        </Col>
                        <Col md={6}>
                            <p className="footer-e">Privacy policy | Terms of service</p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Footer