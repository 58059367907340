import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { Logo } from '../../assets';

function Header() {
    return (
        <Navbar expand="lg" className="navbar-dark">
            <Container>
                <Navbar.Brand to="/"><img src={Logo} className='Logo' alt='' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/">Home</Link>
                        <Link to="./Aboutme">About Me</Link>
                        <Link to="./Collection">Collection</Link>
                        <Link to="./Roadmap">Roadmap</Link>
                        <Link to="./Contactus">Contact Us</Link>
                    </Nav>
                    <Button href="#" className='connect-btn'>Connect Wallet</Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header