import './App.css';
import Header from './components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Pages/Home';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aboutme from './components/Pages/Aboutme';
import Collection from './components/Pages/Collection';
import Roadmap from './components/Pages/Roadmap';
import Contactus from './components/Pages/Contactus';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Aboutme' element={<Aboutme />} />
          <Route path='/Collection' element={<Collection />} />
          <Route path='/Roadmap' element={<Roadmap />} />
          <Route path='/Contactus' element={<Contactus />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
