import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { main1, main2, Roadmap1 } from "../../assets";

function Home() {
    return (
        <>
            <section className="main-1">
                <Container>
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <h3 className="main1-a">
                                Discover <span>Special Collectible</span> <br /> Treasures
                            </h3>
                            <p className="main1-b">
                                The Obbles is the best source for unique NFT collector figures. Whether you're an experienced collector or new to NFTs, The Obbles provides a classic opportunity to possess exclusive digital and physical art pieces beyond the ordinary.
                                <br />
                                Order My NFT Now!
                            </p>
                            <Link to="/" className="explore-btn">
                                Explore
                            </Link>

                            <div className="main1-inner">
                                <div>
                                    <h4 className="main1-c">5M+</h4>
                                    <h4 className="main1-d">Executive Assets</h4>
                                </div>
                                <div>
                                    <h4 className="main1-c">100%</h4>
                                    <h4 className="main1-d">NFT Best Creator</h4>
                                </div>
                                <div>
                                    <h4 className="main1-c">80+</h4>
                                    <h4 className="main1-d">Platform Support</h4>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <img src={main1} className="main1-img" alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="main-2">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <img src={main2} className="main2-img" alt="" />
                        </Col>
                        <Col md={6}>
                            <h3 className="main2-a">About The Obbles</h3>
                            <p className="main1-b">
                                At The Obbles, we are committed to combining the worlds of digital collectibles and generosity. Our special NFT figures, available in bronze, gold, and silver, provide collectors with rare treasures. But our objective extends beyond manufacturing exceptional collectibles; we want to make a positive impact by channeling our earnings to help people in need around the United States. Join us on our adventure to expand your collection while also making a difference in the lives of others.

                            </p>
                            <Link to="./Aboutme" className="explore-btn">
                                Learn More
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="main-3">
                <Container fluid className="p-0">
                    <h3 className="main2-a">Our Collections</h3>
                    <p className="main3-b">
                        Explore The Obbles' magnificent collections, where each NFT figure is a masterpiece created in bronze, gold, or silver. These unique items are intended to attract collectors with detailed decorations and concealed Easter eggs that reveal particular NFTs from our collection. Customize your collectibles using puzzle pieces to give a personal touch to your favorite statues. Discover the ideal combination of creativity and innovation in The Obbles' collections.
                    </p>

                    <div className="banner-div">
                        <Link to="./Collection" className="explore-btn">
                            Buy Now
                        </Link>
                    </div>

                    {/* <div className="nft-div">
                        <div className="img-box">
                            <Link to="">
                                <img src={Nft1} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="">
                                <img src={Nft2} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="">
                                <img src={Nft3} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="">
                                <img src={Nft4} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                        <div className="img-box">
                            <Link to="">
                                <img src={Nft5} className="imgbox-img" alt="" />
                            </Link>
                        </div>
                    </div> */}
                </Container>
            </section>

            <section className="main-4">
                <Container>
                    <h3 className="main2-a">Roadmap to Collect</h3>
                    <p className="main3-b">
                        Our easy-to-follow instructions assist you in owning your exclusive NFTs in no time.
                    </p>

                    <img src={Roadmap1} className="roadmap-img" alt="" />
                </Container>
            </section>
        </>
    );
}

export default Home;
