import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { main2 } from "../../assets";

function Aboutme() {
    return (
        <>
            <section className="main-2">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <img src={main2} className="main2-img" alt="" />
                        </Col>
                        <Col md={6}>
                            <h3 className="main2-a">About The Obbles</h3>
                            <p className="main1-b">
                            At The Obbles, we are committed to combining the worlds of digital collectibles and generosity. Our special NFT figures, available in bronze, gold, and silver, provide collectors with rare treasures. But our objective extends beyond manufacturing exceptional collectibles; we want to make a positive impact by channeling our earnings to help people in need around the United States. Join us on our adventure to expand your collection while also making a difference in the lives of others.

                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Aboutme